import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  type OnInit,
  signal
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';
import {InputNumberModule} from 'primeng/inputnumber';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckoutService} from 'src/app/core/services/checkout.service';
import {IBasket, IGetBasketResponse} from '@GeneratedTsFiles/index';
import {ApiService} from 'src/app/core/services/api.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {DataApiStateService, State} from 'src/app/core/services/data-api-state.service';
import {GeneralService} from 'src/app/core/services/general.service';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ToastService} from 'src/app/core/services/toast.service';
import {InputTextModule} from 'primeng/inputtext';
import {Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {
  CheckoutSidebarBasketItemComponent
} from './checkout-sidebar-basket-item/checkout-sidebar-basket-item.component';

@Component({
  selector: 'app-buy-package-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    ScrollPanelModule,
    InputTextModule,
    InputNumberModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    CheckoutSidebarBasketItemComponent,
  ],
  templateUrl: './buy-package-sidebar.component.html',
  styleUrl: './buy-package-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, MessageService],
})
export class BuyPackageSidebarComponent implements OnInit {
  editMode = input(false);
  inSidebar = input(false);
  inCheckout = input(false);
  sidebarShowDeleteCartItemsButton = input(true);
  showCheckoutButton = input(false);
  showStepTwoButtonBottom = input(false);

  disabledQuantityButtons = signal(false);
  quantities: number[] = [1, 1, 1];
  isPromoCodeInputVisible = signal(false);
  isGoToSelectNewPackageButtonVisible = signal(false);
  promoCode: string = '';
  public readonly generalService = inject(GeneralService);
  private readonly apiService = inject(ApiService);
  private readonly authService = inject(AuthService);
  private readonly checkoutService = inject(CheckoutService);
  private readonly eventBusService = inject(EventBusService);
  private readonly dataStateService = inject(DataApiStateService);
  basket$ = computed(() => this.dataStateService.getBasket.state() || [] as State<any>[]);
  private readonly toastService = inject(ToastService);
  private readonly router = inject(Router);
  private readonly destroy: DestroyRef = inject(DestroyRef);

  constructor(private confirmationService: ConfirmationService, private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.reloadBasketEvent();
    this.checkPackageRoute();
  }

  goToBuyPackage(): void {
    this.navigateTo(['/buy-package/select-new-package']);
  }

  goToCheckout(): void {
    this.goToCheckoutStep(1);
  }

  goToCheckoutStep(step: number): void {
    this.checkoutService.goToCheckoutStep(step);
    this.generalService.cartSidebarVisible.set(false);
  }

  initGetBasket(): void {
    this.apiService.getApiData<IGetBasketResponse>(
      {url: IBasket.getBasket, method: 'GET'},
      {parentId: this.authService.getUser()?.id}
    ).pipe(takeUntilDestroyed(this.destroy))
      .subscribe((response: IGetBasketResponse) => {
        console.log(response);
      });
  }

  togglePromoCodeInputVisibility(): void {
    this.isPromoCodeInputVisible.set(!this.isPromoCodeInputVisible());
  }

  private reloadBasketEvent(): void {
    this.eventBusService.emit(new EmitEvent(Events.StateLoadGetBasket, {parentId: this.authService.getUser()?.id}));
    this.disabledQuantityButtons.set(false);
  }

  private checkPackageRoute(): void {
    this.router.events.pipe(takeUntilDestroyed(this.destroy)).subscribe((evt: any) => {
      if (evt.routerEvent) {
        this.isGoToSelectNewPackageButtonVisible.set(!evt.routerEvent.url.includes('select-new-package'));
      }
    });
  }

  private navigateTo(route: string[]): void {
    this.router.navigate(route);
    this.generalService.cartSidebarVisible.set(false);
  }

}
